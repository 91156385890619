<template>
  <div class="Why">
    <h1>Why Would Someone Make This?</h1>
    <hr/>
    <p>You may be wondering why someone would ever choose to make this project. 
      I made this because I work in a library, and frequently come across books named something like a / the ___ of ___ and ___.
      <br/>To be honest with you, it seemed like the books were always randomly generated names. 
      I never really understood why they would choose to name their books they way they did, 
      as it never seemed to match what the book is about. 
      <br/>
      So if you're a writer, let me name your fantasy book, it's hip, it's cool, and I'm sure it'll sound great. 
     </p>
  </div>
</template>

<style scoped>
p{
  text-align: left;
  padding:10px;
}
h1{
  padding-right:10px;
  padding-left:10px;
  color:#68a19c;
}

.Why{
    
    background-color:white;
    border: solid;
    border-radius: 15px;
    border-color: #68a19c;
    padding-bottom:10px;
    margin:20px;
    
  }

  hr{
    color:#68a19c;
    height: 10px;
    background-color:#68a19c;
  }

</style>